<template>
    <div v-loading="loading">
        <div class="card card-custom card-stretch gutter-b">
            <div class="card-header">
                <div class="card-title">
                     <span class="card-icon">
                        <i class="flaticon2-delivery-package text-primary"></i>
                    </span>
                    <h3 class="card-label"><b class="title-header">メール管理</b></h3>
                </div>
            </div>
            <div class="card-body">
                <form ref="ruleForm" :model="ruleForm"
                         class="form-base" v-on:submit.prevent="isSubmit=true">
                    <div class="row mb-8">
                        <div class="col-md-2">
                            <label class="el-form-item__label form-item-start-template">メール区分</label>
                        </div>
                        <div class="col-md-3">
                            <el-select @change="changeTemplate()" v-model="ruleForm.type" placeholder="選択してください" class="w--10 bg-gray-item">
                                <el-option
                                    v-for="(item,index) in list"
                                    :key="item.type"
                                    :label="item.subject"
                                    :value="item.type">
                                </el-option>
                            </el-select>
                            <div class="error-message" v-if="errors && errors.type">{{errors.type[0]}}</div>
                        </div>
                    </div>

                    <div class="row mb-8">
                        <div class="col-md-2">
                            <label class="el-form-item__label form-item-start-template">メール件名</label>
                        </div>
                        <div class="col-md-3">
                            <el-input  class="bg-gray-item" maxlength="300" placeholder="メールタイトルを入力"
                                       v-model="ruleForm.subject"></el-input>
                            <div class="error-message" v-if="errors && errors.subject">{{errors.subject[0]}}</div>
                        </div>
                    </div>

                    <div class="row mb-8">
                        <div class="col-md-2">
                            <label class="el-form-item__label">利用できる置換文字</label>
                        </div>
                        <div class="col-md-3">
                            <div v-html="string_keyword"></div>
                        </div>
                    </div>
                    <div class="row mb-8">
                        <div class="col-md-2">
                            <label class="el-form-item__label">BCCメールアドレス</label>
                        </div>
                        <div class="col-md-3">
                            <el-input class="bg-gray-item" maxlength="1000" placeholder="BCCメールアドレスを入力"
                                      v-model="ruleForm.forward_mail"></el-input>

                        </div>
                    </div>

                    <div class="row mb-8">
                        <div class="col-md-2">
                            <label class="el-form-item__label form-item-start-template">メール本文</label>
                        </div>
                        <div class="col-md-6">
                            <el-input type="textarea" class="bg-gray-item" rows="15" placeholder=""
                                      v-model="ruleForm.content"></el-input>
                            <div class="error-message" v-if="errors && errors.content">{{errors.content[0]}}</div>
                        </div>
                    </div>
                    <div class="text-center">
                        <button class="ml-md-40 pl-10 pr-10 btn btn-primary " type="submit">入力完了</button>
                    </div>
                </form>
            </div>
        </div>
        <div class="submit-form-item">
            <Dialog v-on:closeDialog="isSubmit=false" v-on:submit="submit" v-bind:dialog="isSubmit"
                    :closeOnClickModal="false" width="28%" btnOk="はい" :showCancelButton="true" btnCancel="いいえ">
                <template slot="header">
                    <div class="modal-header text-center">
                        <h4 style="width: 100%;">確認</h4>
                    </div>
                </template>
                <template slot="content">
                    <div class="textwidget">
                        <p>メール：【{{ruleForm.subject}}】の文言を変更しますか？</p>
                    </div>
                </template>
            </Dialog>
        </div>
        <my-dialog v-on:closeDialog="not_permisson_popup=false" v-on:submit="not_permisson_popup=false;$router.push({name: 'permission-denied'});" v-bind:dialog="not_permisson_popup"
                   :closeOnClickModal="false" width="28%" v-bind:btnOk="$t('DIALOG.BTN_OKIE_PERMISSON_DENIED')" :showCancelButton="false">
            <template slot="header">
                <div class="textwidget text-center">
                    {{ $t('DIALOG.TITLE_PERMISSON_DENIED') }}
                </div>
            </template>
        </my-dialog>
    </div>
</template>
<style lang="scss" scoped>

.w--10 {
    width: 100%;
    font: initial !important;
}
</style>
<script>
import Dialog from "@/view/components/Dialog";
import {
    LIST_TEMPLATE,
    GET_DETAIL,
    UPDATE_DETAIL,
} from "@/core/services/store/template.modal";
import {mapActions, mapGetters} from "vuex";
import {CREATE_FORM_BASE} from "@/core/services/store/formStore";
import * as constants from "@/core/config/constant";
import Form from "@/view/pages/tenant/Form";

export default {
    components: {
        Form,
        Dialog
    },
    name: "name",
    data() {
        return {
            string_keyword:'{{$username}}：会員名 <br>{{$link}}：フォームURL',
            ruleForm:{
                mail_template_id:'',
                subject:'',
                forward_mail:'',
                content:'',
                type:''
            },
            errors: {},
            loading:false,
            isSubmit: false,
            not_permisson_popup: false
        };
    },
    computed: {
        ...mapGetters(["list","detail"]),
    },
    async created() {
        this.loading = true;
        if (!this.hasPermissonCheck('mail_flg')){
            this.not_permisson_popup = true
        }else {
            await this.listTemplate();
        }
        this.loading = false;
    },
    methods: {
        ...mapActions([LIST_TEMPLATE,GET_DETAIL,UPDATE_DETAIL]),
        async changeTemplate(){
            this.loading = true;
            await this.getDetail(this.ruleForm.type);
            this.ruleForm = this.detail;
            this.loading = false;
        },
        async submit(){
            this.isSubmit = false;
            this.loading = true;
            this.errors = {};
            this.$store.dispatch(UPDATE_DETAIL, this.ruleForm).then(data => {
                this.loading = false
                if (data && data.data) {
                    if (data.code === constants.STATUS_CODE.BAD_REQUEST) {
                       this.errors = data.data;
                    }
                    if (data.code === constants.STATUS_CODE.SUCCESS) {
                        this.notifySuccess(this.$t('FORM.SETTING.SUCCESS', {form_name: this.ruleForm.form_name}))
                        this.listTemplate();
                        this.loading = false
                    }
                } else {
                    this.notifyError('error server')
                }
            }).catch(e => {
                this.loading = false
                this.notifyError(e)
            })
        }
    }
}
</script>

<style scoped>

</style>
